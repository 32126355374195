/* This here is for the styling of the pages that are within the login family such as 
the login, register, new password, and forgot password pages. */

.App {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.App h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.App label {
  display: block;
  margin-bottom: 10px;
  text-align: left;
}

.App input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 10px;
}

.App button {
  width: 50%;
  padding: 12px;
  background-color: #f94e4e;
  border: none;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.App button:hover {
  background-color: #9e0000;
}

.App h2 {
  font-size: 32px; 
}

.App a {
  display: block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.App a:hover {
  color: #0056b3;
}

.profile-settings {
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 20px;
  background: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.skin-tone-container {
  display: flex;
  flex-direction: column;
}

.skin-tone-group {
  margin-bottom: 20px;
}

.skin-tone-options {
  display: flex;
  gap: 10px;
}

.skin-tone {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid transparent;
}

.skin-tone.selected {
  border-color: #000; /* Change to a suitable highlight color */
}

