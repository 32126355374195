.profile-settings h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .password-input {
    display: flex;
    align-items: center;
  }
  
  .password-input input {
    flex: 1;
  }
  
  .toggle-password {
    margin-left: 10px;
    padding: 8px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .toggle-password:hover {
    background: #0056b3;
  }
  
  .submit-btn {
    padding: 10px;
    background: #f94e4e;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background: #9e0000;
  }