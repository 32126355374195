.outfits-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .outfit-item {
    width: 350px;
  }
  
  .outfit-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-items: center;
  }
  
  .outfit-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }