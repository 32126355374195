.navbar {
    background-color: #f8f9fa;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-nav {
    display: flex;
    align-items: center;
  }
  
  .nav-item {
    list-style: none;
    margin-right: 20px;
  }
  
  .nav-link {
    text-decoration: none;
    color: #343a40;
    font-weight: 500;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .nav-link:hover {
    background-color: #343a40;
    color: #ffffff;
  }
  
  .nav-link.active {
    background-color: #343a40;
    color: #ffffff;
  }